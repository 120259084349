import * as React from 'react';
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/company.scss';
import CustomButton from '../components/custom-button';
// import CustomCarousel from '../components/carousel';

const Company = () => {
  return (
    <Layout>
      <SEO
        title="About the Ostara Company Pioneering Sustainable Nutrient Solutions | Ostara"
        description="Learn how Ostara is pioneering sustainable phosphate management with innovative technology and enhanced-efficiency fertilizers for data-driven growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Ostara, Company"
      />
      <main className="company">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Who We Are</h1>
                  <p style={{ maxWidth: 550 }}>
                    Ostara is pioneering sustainable phosphate management for
                    data-driven, future-focused growers.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/company-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="company-body-container">
          <Container className="company-body-content-1-container" maxW={1400}>
            <Flex className="company-body-content-1">
              <Box marginBottom={5}>
                <h3>
                  End-to-End Phosphate Management for a Sustainable Future
                </h3>
              </Box>
              <p>
                With enhanced efficiency fertilizers that reduce nutrient runoff
                and technology that recovers excess nutrients from wastewater,
                Ostara is solving some of the agriculture industry’s toughest
                environmental challenges.
              </p>
            </Flex>
          </Container>

          <Container maxW={1400} marginY={[16, 16, '72px', 100]} padding={0}>
            <Divider opacity={1} className="company-divider" />
          </Container>

          <Container className="company-body-content-2-container" maxW={1400}>
            <Box
              border="1px"
              borderColor="#044606"
              borderRadius={30}
              marginX="auto"
              className="company-crop-nutrition"
            >
              <h3>From Nutrient Recovery to Crop Nutrition</h3>
              <p>
                Vancouver-based Ostara was founded in 2005 as a nutrient
                recovery company to help cities turn excess nutrients in
                wastewater into Crystal Green Pearl<sup>®</sup> granular
                fertilizer using our propriety Pearl<sup>®</sup>{' '}
                <Link to="/solutions">
                  <span className="emphasize">
                    nutrient recovery technology.
                  </span>
                </Link>
              </p>
              <p>
                Today, our expanded{' '}
                <Link to="/product">
                  <span className="emphasize">fertilizer options</span>
                </Link>{' '}
                are supported by our new facility dedicated to producing Crystal
                Green<sup>®</sup> fertilizer using the same formula as our
                one-of-a-kind Crystal Green Pearl. This{' '}
                <Link to="/innovation">
                  <span className="emphasize">innovative</span>
                </Link>{' '}
                facility is the first in the world to manufacture granulated
                struvite.
              </p>
            </Box>
            {/* <Box w="90%" maxW={1385} marginX="auto">
              <StaticImage
                src="../images/company-img02.svg"
                placeholder="transparent"
                alt=""
              />
            </Box> */}
          </Container>

          <Container maxW={1400} marginY={[16, 16, '72px', 100]} padding={0}>
            <Divider opacity={1} className="company-divider" />
          </Container>

          <Container className="company-body-content" maxW={1400}>
            <Box
              display={['block', 'block', 'flex', 'flex']}
              justifyContent="space-between"
            >
              <Box
                background="#044606"
                borderRadius={30}
                paddingTop={[10, 10, 10, 20]}
                w={['100%', '100%', '46.8%']}
                className="company-card company-card--green"
              >
                <Heading
                  as="h3"
                  lineHeight={1.3}
                  marginBottom={5}
                  paddingX={[8, 8, 8, 12]}
                  color="#fff"
                  className="company-card__title"
                >
                  The First and Only Crop Driven Release™ Phosphate Fertilizers
                </Heading>
                <Text
                  minH={150}
                  marginBottom={5}
                  paddingX={[8, 8, 8, 12]}
                  color="#fff"
                >
                  Ostara’s Crystal Green and Crystal Green Pearl fertilizers
                  deliver phosphate, magnesium and nitrogen as unique Crop
                  Driven Release™, nutrient packed granules.
                </Text>
                <Box marginBottom={[14, 14, 14, 20]} paddingX={[8, 8, 8, 12]}>
                  <CustomButton to="/product">EXPLORE FERTILIZERS</CustomButton>
                </Box>
                <Box marginTop="auto">
                  <StaticImage
                    src="../images/company-cg-granules-zoom.png"
                    placeholder="transparent"
                    alt="EXPLORE FERTILIZERS"
                    className="company-card__image"
                  />
                </Box>
              </Box>
              <Box
                background="#fff"
                border="1px"
                borderColor="#044606"
                borderRadius={30}
                paddingTop={[10, 10, 10, 20]}
                w={['100%', '100%', '46.8%']}
                className="company-card"
              >
                <Heading
                  as="h3"
                  lineHeight={1.3}
                  marginBottom={5}
                  paddingX={[8, 8, 8, 14]}
                  color="#000"
                  className="company-card__title"
                >
                  For the Data-Driven Farmers of Today and Tomorrow
                </Heading>
                <Text
                  minH={150}
                  marginBottom={5}
                  paddingX={[8, 8, 8, 14]}
                  color="#000"
                >
                  Our fertilizers are proven to enhance yields and quality
                  across a variety of crops and soil types while preventing soil
                  tie-up and leaching of critical nutrients.
                </Text>
                <Box marginBottom={[14, 14, 14, 20]} paddingX={[8, 8, 8, 14]}>
                  <CustomButton to="/data-insights">VIEW THE DATA</CustomButton>
                </Box>
                <Box borderTop="1px" borderColor="#044606" marginTop="auto">
                  <StaticImage
                    src="../images/company-img-2.png"
                    placeholder="transparent"
                    alt="fertilizers data"
                    className="company-card__image"
                  />
                </Box>
              </Box>
            </Box>
          </Container>

          <Container maxW={1400} marginY={[16, 16, '72px', 100]} padding={0}>
            <Divider opacity={1} className="company-divider" />
          </Container>

          <Container
            maxW={1400}
            marginY={[16, 16, '72px', 100]}
            paddingX={[6, 6, 6, 0]}
          >
            <Heading
              as="h3"
              color="#044606"
              textAlign="center"
              fontWeight="light"
              className="company-featured__title"
            >
              As Featured In:
            </Heading>
            <Box
              display="flex"
              alignItems="center"
              w="100%"
              className="company-featured"
            >
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-world-economic-forum.svg"
                  alt="world economic forum"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-technology-pioneers-2011.svg"
                  alt="technology pioneers 2011"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-global-cleantech.svg"
                  alt="global clean tech"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-globe-awards.svg"
                  alt="global awards"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-sustainia.svg"
                  alt="sustainia"
                  placeholder="transparent"
                />
                <StaticImage
                  src="../images/logo-deloitte.svg"
                  alt="deloitte"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-waters-next.png"
                  alt="water's next"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-wef.svg"
                  alt="wef"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-going-green.svg"
                  alt="going green"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-ad.png"
                  alt=""
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-blue-tech.png"
                  alt="blue tech"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-audubon.svg"
                  alt="audubon international"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-nutrient.svg"
                  alt="nutrient stewardship"
                  placeholder="transparent"
                />
              </Box>
              <Box
                marginX={4}
                marginBottom={3}
                className="company-featured__img"
              >
                <StaticImage
                  src="../images/logo-x.png"
                  alt=""
                  placeholder="transparent"
                />
              </Box>
            </Box>
          </Container>

          <Container maxW={1400} marginY={[16, 16, '72px', 30]} padding={0}>
            <Divider opacity={1} className="company-divider" />
          </Container>

          {/* <Box w="100%" p={4} marginTop={20}>
            <Container className="carousel-section" maxW={1400}>
              <h4>Related Media</h4>
              <CustomCarousel className="carousel-container" />
            </Container>
          </Box> */}
        </Box>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Company;
